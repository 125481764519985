<template>
	<yandex-map ymap-class="yandex-map"
				ref="map"
				:settings="this.settings"
				:coords="this.coords"
				:controls="this.controls"
				:zoom="this.zoom"
				:options="this.options"
				@map-was-initialized="this.mapInitialized"
				:init-without-markers="false"
				:object-manager-clusterize="true"
				:use-object-manager="true"
				:scroll-zoom="false"
				:cluster-callbacks="this.callbacks"
				:cluster-options="this.clusters"
				:show-all-markers="true">
		<ymap-marker v-for="(object, index) in this.objects"
					 :key="index"
					 :icon="this.markerIcon"
					 marker-type="placemark"
					 :cluster-name="1"
					 :coords="[object.geo.lat, object.geo.lng] || [0, 0]"
					 :marker-id="'point_' + object.id"
					 :hint-content="object.title + ',<br/>' + object.address" />
	</yandex-map>
</template>

<script>

import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';
import Pagination from "@/components/common/Pagination";
import CommonService from "@/services/CommonService";

const ymaps = null;

export default {
	name: 'ObjectsMap',
	components: {yandexMap, ymapMarker},
	props: {
		inputObjects: {
			type: Array,
			required: true
		},
		inputCoords: {
			type: Array,
			required: true
		},
		inputZoom: {
			type: Number,
			default: 12,
			required: true
		},
		inputControls: {
			type: Array,
		},
	},
	data() {
		return {
			settings: {
				apiKey: this.$root.config.yandexMap.apiKey,
				lang: this.$root.config.yandexMap.lang,
				coordorder: this.$root.config.yandexMap.coordorder,
				enterprise: this.$root.config.yandexMap.enterprise,
				version: this.$root.config.yandexMap.version
			},
			options: {
				suppressMapOpenBlock: true,
				autoFitToViewport: 'always'
			},
			clusters: {
				1: {
					preset: 'islands#darkGreenClusterIcons',
					clusterDisableClickZoom: true,
					hasBalloon: false,
					clusterLayout: ['<div class=cluster-icon>{{ properties.geoObjects.length }}</div>'].join('')
				},
				layout: '<div>{{ properties.balloonContentHeader }}</div><div>{{ properties.balloonContentBody }}</div><div>{{ properties.balloonContentFooter }}</div>',
			},
			callbacks: {
				'1': { click: this.getDataPoint }
			},
			map: {},
			objectManager: {},
			markerIcon: {
				layout: 'default#image',
				imageHref: require('@/assets/svg/map-pin.svg'),
				imageSize: [33, 36],
				imageOffset: [-14, -36],
				contentOffset: [0, 15],
				contentLayout: '<div>$[properties.iconContent]</div>'
			},
			is_processed: false
		};
	},
	methods: {
		getDataCluster(object) {
			const cluster = this.map.geoObjects.get(0);
			this.map.setBounds(cluster.getBounds());

			CommonService.log('log', 'getDataCluster()', cluster);

			this.$emit("set-coords", object.get("coords"));
			this.$emit("get-data-point", object.get("coords").join());
		},
		getDataPoint(point) {


			const type = point.get("type");
			const coords = point.get("coords");
			const clusterId = point.get("objectId");
			const objectManager = this.map.geoObjects.get(0);
			const cluster = objectManager.clusters.getById(clusterId);
			if (cluster && !this.is_processed) {

				this.is_processed = true;

				const geoObjects = cluster.properties.geoObjects;

				let objects = [];
				geoObjects.forEach(object => objects.push(object.id.replace('point_', '')));

				CommonService.log('log', 'getDataPoint()', {type: type, coords: coords, objects: objects});

				//this.coords = coords;
				if (objects.length <= 1000) {
					this.$emit("set-data-point", {type: type, coords: coords, objects: objects});
					setTimeout(() => {
						this.is_processed = false;
					}, 600);
				} else {
					this.clusters[1].disableClickZoom = false;
				}
			}
		},
		zoomToPoint(event) {

			CommonService.log('debug', 'zoomToPoint()', {event: event});

		},
		mapInitialized(map) {
			this.map = map;
			console.log(this.map.geoObjects);
			this.objectManager = map.geoObjects;
			map.container.fitToViewport();

			this.$emit("map-initializing", {map: map});
			
			let _this = this;

			this.map.geoObjects.events.add('click', function (event) {

				let objects = [];
				if (!_this.is_processed && typeof event.get('objectId') !== "undefined") {

					_this.is_processed = true;

					let object_id = event.get('objectId').toString();
					if (object_id)
						objects.push(object_id.replace('point_', ''));

					_this.$emit("set-data-point", {objects: objects});
					setTimeout(() => {
						_this.is_processed = false;
					}, 600);
				}

				CommonService.log('debug', 'getDataPoint()', {objects: objects});

			});

		},
	},
	async mounted() {
		await loadYmap({...this.settings, debug: true});
	},
	watch: {
		'objects': function(val, oldVal) {
			this.is_processed = false;
		}
	},
	computed: {
		objects() {
			return this.$props.inputObjects ?? [];
		},
		coords() {
			return this.$props.inputCoords ?? this.$root.config.yandexMap.coords;
		},
		zoom() {
			return this.$props.inputZoom;
		},
		controls() {
			return this.$props.inputControls ?? ['fullscreenControl', 'zoomControl'];
		},
	}
};
</script>